import baseURL from "@/utils/config";
import request from "@/plugins/axios"

export function getLogin(data,langEnv=3){
  return request({
      url:`${baseURL.api}/api/login`,
      method: "post",
      data,

  });
}